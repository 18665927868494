import './App.css';
import aDLogo from "./res/img/aD-Logo.svg";
import heroImage from "./res/img/hero.png";
import hostingCTA from "./res/img/hostingCTA.png";

import AnchorLink from 'react-anchor-link-smooth-scroll'


function App() {
	return (
		<div className={"bg-black text-white"}>
			<div class="w-11/12 mx-auto">
				{/* Navbar */}
				<header className="text-gray-300">
					<div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
						<a className="flex font-medium items-center text-gray-100 mb-4 md:mb-0" href={"#"}>
							<img src={aDLogo} className={"w-40"} style={{marginTop: "3px"}}/>
						</a>
						<nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
							<AnchorLink className="mr-5 hover:text-gray-100" href={"#entwicklung"}>Entwicklung</AnchorLink>
							<AnchorLink className="mr-5 hover:text-gray-100" href={"#hosting"}>Hosting</AnchorLink>
							<AnchorLink className="mr-5 hover:text-gray-100" href={"#upi"}>UPI</AnchorLink>
							<AnchorLink className="mr-5 hover:text-gray-100" href={"#print"}>Print</AnchorLink>
						</nav>
						<button	className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0" onClick={() => {window.location.href = "/kontakt"}}>
							Kontakt
							<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
								 stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
								<path d="M5 12h14M12 5l7 7-7 7"/>
							</svg>
						</button>
					</div>
				</header>

				{/* Hero */}
				<section class="text-gray-300 font-sans">
					<div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
						<div
							class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
							<h1 class="leading-relaxed sm:text-5xl text-4xl mb-6 font-bold text-gray-100"
								style={{lineHeight: 1.1}}>
								Digitale Lösungen<br/>
								leicht gemacht.
							</h1>
							<p class="mb-12 w-10/12 leading-relaxed">
								allroundDigital schreibt Texte, damit Jakob diese lesen kann. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
							</p>
							<div class="flex justify-center">
								<button class="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-base">
									Mehr Erfahren
								</button>
								<button class="ml-4 inline-flex text-gray-300 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 rounded text-base">
									Kontakt
								</button>
							</div>
						</div>
						<div class="md:w-1/2 w-5/6">
							<img class="object-cover object-center rounded" alt="hero" src={heroImage}/>
						</div>
					</div>
				</section>

				{/* Entwicklung */}
				<section id="entwicklung" className="text-gray-300 font-sans">
					<div className="container px-5 py-24 mx-auto">
						<div className="w-full mb-20">
							<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
								<h3 className="sm:text-l text-m mb-2 text-blue-500">Entwicklung</h3>
								<h2 className="sm:text-4xl text-3xl font-bold mb-2 text-gray-100">
									Website, App oder Software
								</h2>
							</div>
							<p className="lg:w-1/2 w-full leading-relaxed text-base">Whatever cardigan tote bag tumblr
								hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you
								probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck
								ugh squid celiac humblebrag.</p>
						</div>
						<div className="flex flex-wrap -m-4">
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-blue-500 text-xs font-medium mb-1">Website</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Wordpress, Strapi und viel mehr</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-blue-500 text-xs font-medium mb-1">App</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Nur einen Tipp entfernt</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-blue-500 text-xs font-medium mb-1">Software</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Irgendwas cleveres mit Software</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Hosting */}
				<section id="hosting" className="text-gray-300 font-sans">
					<div className="container px-5 py-24 mx-auto">
						<div className="w-full mb-20">
							<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
								<h3 className="sm:text-l text-m mb-2 text-purple-500">Hosting</h3>
								<h2 className="sm:text-4xl text-3xl font-bold mb-2 text-gray-100">
									Performantes Hosting,<br />persönlicher Support
								</h2>
							</div>
							<p className="lg:w-1/2 w-full leading-relaxed text-base">Whatever cardigan tote bag tumblr
								hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you
								probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck
								ugh squid celiac humblebrag.</p>
						</div>
						<div className="flex flex-wrap -m-4">
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-purple-500 text-xs font-medium mb-1">Essential</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Das Rundum-Paket<br />für Privatkunden und KMU</h2>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<h4 className="text-3xl text-gray-100 leading-none flex items-end pt-3 mt-4 ml-auto w-max">
										<span className="font-medium">4,00 €</span>
										<span className="text-base ml-1 font-normal text-gray-500">/mtl.</span>
									</h4>
								</div>
							</div>

							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-purple-500 text-xs font-medium mb-1">Large</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Das Rundum-Paket<br />für Privatkunden und KMU</h2>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
										10 GB Speicher
									</p>
									<h4 className="text-3xl text-gray-100 leading-none flex items-end pt-3 mt-4 ml-auto w-max">
										<span className="font-medium">8,00 €</span>
										<span className="text-base ml-1 font-normal text-gray-500">/mtl.</span>
									</h4>
								</div>
							</div>

							<div className="xl:w-1/3 md:w-1/2 p-4">
							<div className="bg-gray-900 p-6 rounded-lg">
								<h3 className="tracking-widest text-purple-500 text-xs font-medium mb-1">Pro</h3>
								<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Das Rundum-Paket<br />für Privatkunden und KMU</h2>
								<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
									10 GB Speicher
								</p>
								<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
									10 GB Speicher
								</p>
								<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
									10 GB Speicher
								</p>
								<p className="flex items-center text-gray-300 mb-2">
										<span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-400 text-white rounded-full flex-shrink-0">
										  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"
											   className="w-3 h-3" viewBox="0 0 24 24">
											<path d="M20 6L9 17l-5-5"/>
										  </svg>
										</span>
									10 GB Speicher
								</p>
								<h4 className="text-3xl text-gray-500 leading-none flex items-end pt-3 mt-4 ml-auto w-max">
									<span className="font-medium text-lg">Auf Anfrage</span>
								</h4>
							</div>
						</div>
						</div>
					</div>
					<div className="container py-12 mb-2 mx-auto rounded bg-center bg-cover" style={{backgroundImage: `url(${hostingCTA})`}}>
						<div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
							<h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-100">
								Hier steht ein Satz, der dich zum Kontakt verleiten soll.
							</h1>
						</div>
						<div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto mt-4">
							<button	className="flex-shrink-0 text-white bg-gray-800 border-0 py-1 px-6 focus:outline-none hover:bg-gray-600 rounded text-base mt-10 sm:mt-0">
								Kontakt
							</button>
						</div>
					</div>
				</section>

				{/* UPI */}
				<section id="upi" className="text-gray-300 font-sans">
					<div className="container px-5 py-24 mx-auto">
						<div className="w-full mb-20">
							<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
								<h3 className="sm:text-l text-m mb-2 text-pink-500">Universal Project Infrastructure</h3>
								<h2 className="sm:text-4xl text-3xl font-bold mb-2 text-gray-100">
									Der Grundbaustein digitaler Projekte
								</h2>
							</div>
							<p className="lg:w-1/2 w-full leading-relaxed text-base">Whatever cardigan tote bag tumblr
								hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you
								probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck
								ugh squid celiac humblebrag.</p>
						</div>
						<div className="flex flex-wrap -m-4">
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-pink-500 text-xs font-medium mb-1">SaaS</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">CMS, Analytics und mehr</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-pink-500 text-xs font-medium mb-1">Backend</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Datenbanken, APIs, Automation</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-pink-500 text-xs font-medium mb-1">VPS</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Eigene Software, solide gehostet</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section >

				{/* Print */}
				<section id="print" className="text-gray-300 font-sans">
					<div className="container px-5 py-24 mx-auto">
						<div className="w-full mb-20">
							<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
								<h3 className="sm:text-l text-m mb-2 text-yellow-500">Print</h3>
								<h2 className="sm:text-4xl text-3xl font-bold mb-2 text-gray-100">
									Visitenkarten, Flyer und viel mehr
								</h2>
							</div>
							<p className="lg:w-1/2 w-full leading-relaxed text-base">Whatever cardigan tote bag tumblr
								hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you
								probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck
								ugh squid celiac humblebrag.</p>
						</div>
						<div className="flex flex-wrap -m-4">
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-yellow-500 text-xs font-medium mb-1">Website</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Wordpress, Strapi und viel mehr</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-yellow-500 text-xs font-medium mb-1">App</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Nur einen Tipp entfernt</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
							<div className="xl:w-1/3 md:w-1/2 p-4">
								<div className="bg-gray-900 p-6 rounded-lg">
									<h3 className="tracking-widest text-yellow-500 text-xs font-medium mb-1">Software</h3>
									<h2 className="text-xl leading-tight text-gray-100 font-medium mb-4">Irgendwas cleveres mit Software</h2>
									<p className="leading-relaxed text-base">
										Wir machen Websites einfach. Lorem ipsum dolor sit amet, consetetur sadipscing
										elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
										erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
										Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Footer */}
				<footer className="text-gray-300">
					<div
						className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col">
						<div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
							<a className="flex font-medium items-center md:justify-start justify-center text-gray-100">
								<img src={aDLogo} className={"w-9/12"} />
							</a>
							<p className="mt-2 text-sm text-gray-500">Air plant banjo lyft occupy retro adaptogen
								indego</p>
						</div>
						<div
							className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
							<div className="lg:w-1/4 md:w-1/2 w-full px-4">
								<h2 className="font-medium text-gray-100 tracking-widest text-sm mb-3">Unternehmen</h2>
								<nav className="list-none mb-10">
									<li>
										<a className="text-gray-400 hover:text-gray-200">Impressum</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Datenschutz / Disclaimer</a>
									</li>
								</nav>
							</div>
							<div className="lg:w-1/4 md:w-1/2 w-full px-4">
								<h2 className="font-medium text-gray-100 tracking-widest text-sm mb-3">Dienste</h2>
								<nav className="list-none mb-10">
									<li>
										<a className="text-gray-400 hover:text-gray-200">Analytics</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Status</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Open Source</a>
									</li>
								</nav>
							</div>
							<div className="lg:w-1/4 md:w-1/2 w-full px-4">
								<h2 className="font-medium text-gray-100 tracking-widest text-sm mb-3">Weitere</h2>
								<nav className="list-none mb-10">
									<li>
										<a className="text-gray-400 hover:text-gray-200">First Link</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Second Link</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Third Link</a>
									</li>
									<li>
										<a className="text-gray-400 hover:text-gray-200">Fourth Link</a>
									</li>
								</nav>
							</div>
						</div>
					</div>
					<div className="bg-gray-900 rounded">
						<div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
							<p className="text-gray-500 text-sm text-center sm:text-left"><span className={"text-gray-400"}>© 2020 allroundDigital</span><span className={"text-blue-500 font-bold"}>˙</span> von Niklas Schrötler Development
							</p>
							<span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
								<a className="ml-3 text-gray-500">
								  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5"
									   viewBox="0 0 24 24">
									<path
										d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"/>
								  </svg>
								</a>
								<a className="ml-3 text-gray-500">
								  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									   className="w-5 h-5" viewBox="0 0 24 24">
									<rect width="20" height="20" x="2" y="2" rx="5" ry="5"/>
									<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"/>
								  </svg>
								</a>
								<a className="ml-3 text-gray-500">
								  <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
									   className="w-5 h-5" viewBox="0 0 24 24">
									<path stroke="none"
										  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"/>
									<circle cx="4" cy="4" r="2" stroke="none"/>
								  </svg>
								</a>
							</span>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
}

export default App;
